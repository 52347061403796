import cn from 'classnames';
import * as React from 'react';
import Icon from '../Icon/Icon';

import './Dropdown.sass';

class Dropdown extends React.Component {

  state = {
    opened : false,
  };

  componentDidMount() {
    this.setState({ id : 'a' + parseInt(Math.random() * 1000) });

    if (!this.props.controlled) {
      document.addEventListener('click', this.hideDropdown);
      document.addEventListener('scroll', this.hideDropdown);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpened !== this.props.isOpened) {
      this.setState({
        opened : nextProps.isOpened,
      });
    }
  };

  componentWillUnmount() {
    if (!this.props.controlled) {
      document.removeEventListener('click', this.hideDropdown);
      document.removeEventListener('scroll', this.hideDropdown);
    }
  }

  hideDropdown = (e) => {
    const isOutsideClick = !document.querySelector(`#${this.state.id}`).contains(e.target);
    if (isOutsideClick) {
      this.setState({ opened : false });
    }
  };

  toggleDropdownOpen = () => {
    this.setState({
      opened : !this.state.opened,
    });
  };

  render() {
    const { children, button, className, controlled, showArrow, position } = this.props;

    const { opened } = this.state;

    return (
      <div
        id={this.state.id}
        className={cn(`dropdown -${position}`, {
          '-opened'     : opened,
          '-show-arrow' : showArrow,
        }, className)}
        onClick={controlled ? null : this.toggleDropdownOpen}
      >
        {button && (
          <div className='dropdown__button'>
            <div className='dropdown__button-content'>
              {this.props.button}
            </div>
            {showArrow && (
              <div className='dropdown__arrow'>
                <Icon name='icon-arrow-down'/>
              </div>
            )}
          </div>
        )}
        <div className='dropdown__content'>
          {children}
        </div>
      </div>
    );
  }
}

Dropdown.defaultProps = {
  showArrow : true,
  position  : 'left',
};

Dropdown.Item = ({ icon, title, component, componentProps, onClick }) => {
  const Component = component || 'div';
  return (
    <Component className={cn('dropdown-item', { '-has-icon' : !!icon })} {...componentProps} onClick={onClick}>
      {icon && (
        <div className='dropdown-item__icon'>
          {icon}
        </div>
      )}
      <div className='dropdown-item__title'>
        {title}
      </div>
    </Component>
  );
};

Dropdown.Label   = ({ children }) => <div className='dropdown-label'>{children}</div>;
Dropdown.Button  = ({ children }) => <div className='dropdown-button'>{children}</div>;
Dropdown.Divider = () => <div className='dropdown-divider'/>;

export default Dropdown;

import * as React from "react"
import {Router} from '@reach/router';
import { BLOG_POSTS } from "../../../constants/constants"
import Footer from "../../Footer/Footer"
import Header from "../../Header/Header"
import Icon from "../../UI/Icon/Icon"
import Dropdown from "../../UI/Dropdown/Dropdown"
import PostPreview from "../../UI/PostPreview/PostPreview"

import "./BlogPage.sass"
import SinglePostPage from "../SinglePostPage/SinglePostPage"

const categories = [
  {value: 'all', label: 'All'},
  {value: 'technologies', label: 'Technologies'},
  {value: 'business', label: 'Business'},
  {value: 'management', label: 'Management'},
  {value: 'psychology', label: 'Psychology'},
]

class BlogPage extends React.Component {

  state = {
    category: categories[0].value,
  }

  onSelectCategory = (category) => () => {
    this.setState({
      category,
    })
  }

  render() {
    const currentCategory = categories.find(item => this.state.category === item.value).label;

    return (
      <div className='blog'>
        <Header/>
        <div className='blog__intro'>
          <div className='max-width'>
            <div className='blog__filter'>
              <span>Category:</span>
              <Dropdown button={currentCategory}>
                {categories.map(item => <Dropdown.Item onClick={this.onSelectCategory(item.value)} title={item.label} key={item.value}/>)}
              </Dropdown>
            </div>
            <Icon name='heading-blog' className='heading' />
          </div>
        </div>
        <div className='blog__content max-width'>
          <div className='blog__content-list'>
            {BLOG_POSTS.map(item => <PostPreview {...item} key={item.id} />)}
          </div>
        </div>
        <Footer/>
      </div>
    )
  }
}

export default BlogPage

import React, { Component } from 'react'
import BlogPage from "../components/Pages/BlogPage/BlogPage"
import PageWrapper from '../components/PageWrapper/PageWrapper'
import SEO from '../components/seo'

class BlogPosts extends Component {
  render() {
    return null;

    return (
      <PageWrapper>
        <SEO title='Blog'/>
        <BlogPage/>
      </PageWrapper>
    )
  }
}

export default BlogPosts

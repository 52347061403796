import { Link } from "gatsby"
import * as React from "react"

import "./PostPreview.sass"

class PostPreview extends React.Component<{
  previewImage: string,
  author: string,
  title: string,
  lead: string,
  date: string,
  slug: string,
}> {
  render() {
    const { previewImage, author, title, lead, date, slug } = this.props
    return (
      <div className='post'>
        <Link to={`/blog-posts/${slug}`} className='post__image'>
          <img src={previewImage} alt=''/>
        </Link>
        <div className='post__info'>
          <Link to={`/blog-posts/${slug}`} className='post__title'>{title}</Link>
          <div className='post__lead'>{lead}</div>
          <div className='post__bottom'>
            <div className='post__author'>{author}</div>
            <div className='post__date'>{date}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default PostPreview
